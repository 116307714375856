const SET_USER = 'SET_USER';

export default {
   state: {
      userType: "admin",
      userName: "",
   },
   getters: {
      userName: state => {
         if (state.userName) {
            return state.userName;
         } else if (localStorage.getItem("skilltree_cms_username")) {
            return localStorage.getItem("skilltree_cms_username")
         } else {
            return "";
         }
      }
   },
   actions: {
      setUser: ({ commit }, payload) => {
         if (payload.userName != "") {
            localStorage.setItem("skilltree_cms_username", payload.userName);
         } else if (localStorage.getItem("skilltree_cms_username")) {
            localStorage.removeItem("skilltree_cms_username");
         }

         commit(SET_USER, payload);
      },
   },
   mutations: {
      [SET_USER]: (state, payload) => {
         state.userName = payload.userName;
      },
   },
}
