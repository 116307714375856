

export default {
    install(Vue, options) {
        Vue.prototype.$fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;
            const formatDate = Vue.prototype.$formatter.formatDate;

            const displayError = (error) => {
                if (error && Object.keys(error).length > 0) {
                    if (error.data !== null) {
                        if (error.data.code && error.data.message) {
                            return {
                                code: error.data.code,
                                message: typeof error.data.message === 'string' ? (error.data.message).toUpperCase() : JSON.stringify(error.data.message)
                            }

                        } else {
                            return {
                                code: '',
                                message: JSON.stringify(error.data.message)
                            };
                        }
                    }
                }

                return {
                    code: '',
                    message: '無法與伺服器連線，請檢查網路連接'
                };
            }

            this.AuthCheck = async function () {
                const p = {}
                try {
                    await Vue.prototype.$XHR.api('admin/profile', p);
                    devLog('----------> XHR [SUCCESS]: AuthCheck');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: AuthCheck');
                    devLog(res);
                    return Promise.resolve(false);
                }
            }

            this.SendBeacon = async function (pulsemode = false) {
                const p = {
                    data: JSON.stringify({})
                }
                try {
                    await Vue.prototype.$XHR.api(`admin/${pulsemode ? 'pulse' : 'beacon'}`, p);
                    devLog('----------> XHR [SUCCESS]: SendBeacon');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SendBeacon');
                    devLog(res);
                    return Promise.resolve(false);
                }
            }

            this.Logout = async function () {
                try {
                    await Vue.prototype.$XHR.api('admin/logout');
                    devLog('----------> XHR [SUCCESS]: Logout');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Logout');
                    devLog(res);
                    return Promise.resolve(false);
                }
            }

            /**
             * ----------- System
             */
            this.GetSystemSetting = async function () {
                try {
                    const p = {
                        data: JSON.stringify({})
                    }
                    const data = await Vue.prototype.$XHR.api('system/get', p);
                    devLog('----------> XHR [SUCCESS]: GetSystemSetting');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSystemSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateSystemSetting = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }

                    const data = await Vue.prototype.$XHR.api('system/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateSystemSetting');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateSystemSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateSystemSetting = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('system/apply', p);
                    devLog('----------> XHR [SUCCESS]: CreateSystemSetting');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateSystemSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- User
             */
            this.GetUsers = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('user/list', p);
                    if (DataValid(data.count) && DataValid(data.user, true)) {
                        devLog('----------> XHR [SUCCESS]: GetUsers');
                        return Promise.resolve({
                            total: data.count,
                            data: data.user
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetUsers');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUsers');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetUserById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            scope: "dashboard"
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('user/user_profile', p);
                    devLog('----------> XHR [SUCCESS]: GetUserById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetUserActiveCredit = async function (user_id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            user_id
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('credit/user_credit', p);
                    devLog('----------> XHR [SUCCESS]: GetUserActiveCredit');
                    if (DataValid(data.amount)) {
                        return Promise.resolve(data.amount);
                    } else {
                        return Promise.resolve(0);
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserActiveCredit');
                    devLog(res);
                    return Promise.resolve(0);
                }
            }

            /**
             * User申請緊回贈的積分
             */
            this.GetUserPendingCredits = async function (user_id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            scope: 'basic',
                            uid: user_id,
                            filter: 'pending',
                            limit: 999,
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('credit_draw/get_all', p);
                    devLog('----------> XHR [SUCCESS]: GetUserPendingCredits');
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        // const pendingData = data.course.filter(el => el.status === 'pending');
                        const credits = data.course.reduce((value, item) => item.amount + value, 0);
                        return Promise.resolve(credits);
                    } else {
                        return Promise.resolve(0);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserPendingCredits');
                    devLog(res);
                    return Promise.resolve(0);
                }
            }

            this.GetUserCreditInfo = async function (user_id) {
                const obj = {
                    credit: 0,
                    course_total: 0,
                    amount_total: 0,
                }

                try {
                    const p = {
                        data: JSON.stringify({
                            id: user_id
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('user/user_credit_summary', p);
                    devLog('----------> XHR [SUCCESS]: GetUserCreditInfo');


                    if (DataValid(data)) {
                        obj['credit'] = data.credit || 0;
                        obj['course_total'] = data.refund_order || 0;
                        obj['amount_total'] = data.draw_credit || 0;
                    }

                    return Promise.resolve(obj);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserCreditInfo');
                    devLog(res);
                    return Promise.resolve(obj);
                }
            }

            // this.GetUserCreditInfo = async function (user_id) {
            //     try {
            //         const p = {
            //             data: JSON.stringify({
            //                 scope: 'basic',
            //                 limit: 999,
            //                 filter: user_id.toString()
            //             })
            //         }
            //         const data = await Vue.prototype.$XHR.api('credit/get_all', p);
            //         devLog('----------> XHR [SUCCESS]: GetUserCreditInfo');
            //         if (DataValid(data.count) && DataValid(data.course, true)) {
            //             const credits = data.course.reduce((value, item) => item.amount + value, 0);
            //             return Promise.resolve({
            //                 course_total: data.count,
            //                 amount_total: credits
            //             });
            //         } else {
            //             return Promise.resolve({
            //                 course_total: 0,
            //                 amount_total: 0
            //             });
            //         }
            //     } catch (res) {
            //         devLog('----------> XHR [FAIL]: GetUserCreditInfo');
            //         devLog(res);
            //         return Promise.resolve({
            //             course_total: 0,
            //             amount_total: 0
            //         });
            //     }
            // }

            this.UpdateUser = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('user/update_user/profile', p);
                    devLog('----------> XHR [SUCCESS]: UpdateUser');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateUser');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateWorkshop = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('user/add_workshop', p);
                    devLog('----------> XHR [SUCCESS]: CreateWorkshop');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateWorkshop');
                    devLog(res);
                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "電郵已注册";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateWorkshopApproval = async function (user_id, approve) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id: user_id,
                            approve, // true / false
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('user/approval', p);
                    devLog('----------> XHR [SUCCESS]: UpdateWorkshopApproval');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateWorkshopApproval');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetActiveWorkshops = async function (payload = {}) {
                let upload = {
                    scope: 'basic',
                    limit: 999
                };

                for (const key in payload) {
                    upload[key] = payload[key];
                }
                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('user/get_workshop', p);
                    if (DataValid(data.count) && DataValid(data.user, true)) {
                        devLog('----------> XHR [SUCCESS]: GetActiveWorkshops');
                        return Promise.resolve({
                            total: data.count,
                            data: data.user
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetActiveWorkshops');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetActiveWorkshops');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }



            /**
             * ----------- Order
             */
            this.GetOrders = async function (payload = {}) {
                let upload = {
                    scope: 'detail'
                };

                for (const key in payload) {
                    upload[key] = payload[key];
                }

                if (upload["end_date"]) {
                    const endDate = new Date(`${upload["end_date"]}T00:00:00+08:00`);
                    endDate.setDate(endDate.getDate() + 1);
                    upload["end_date"] = formatDate(endDate);
                }
                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('order/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetOrders');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetOrders');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrders');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetOrderById = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('order/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetOrderById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrderById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetOrdersByIdList = async function (idList) {
                let upload = {
                    scope: 'complete',
                    id_list: idList
                };

                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('order/get_multi_order', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetOrdersByIdList');
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrdersByIdList');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrdersByIdList');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetPaymentByOrderId = async function (order_id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            order_id
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('payment/get', p);
                    devLog('----------> XHR [SUCCESS]: GetPaymentByOrderId');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPaymentByOrderId');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateOrderPayment = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('payment/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateOrderPayment');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateOrderPayment');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateOrderStatus = async function (id, status) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            order_status: status
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('order/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateOrderStatus');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateOrderStatus');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Course Category
             */
            this.GetAllCourseCategories = async function (full = false) {
                let apiKey = 'category/all';
                if (full === true) {
                    apiKey = 'category/all_detail';
                }

                try {
                    const data = await Vue.prototype.$XHR.api(apiKey, {});
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetAllCourseCategories');
                        devLog(data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAllCourseCategories');
                        devLog(data);
                        return Promise.reject([]);
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAllCourseCategories');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCourseCategoryById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({ id })
                    }
                    const data = await Vue.prototype.$XHR.api('category/id', p);
                    devLog('----------> XHR [SUCCESS]: GetCourseCategoryById');
                    if (Array.isArray(data) && data.length > 0 && DataValid(data[0].id === id)) {
                        return Promise.resolve(data[0]);
                    } else if (typeof data === "object" && data.id === id) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateCourseCategory = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('category/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateCourseCategory');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateCourseCategory');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "課程分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateCourseCategory = async function (id, payload) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('category/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateCourseCategory');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourseCategory');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "課程分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.RemoveCourseCategory = async function (id, payload) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            active: false,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('category/update', p);
                    if (data) {
                        devLog('----------> XHR [SUCCESS]: RemoveCourseCategory');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: RemoveCourseCategory');
                        return Promise.reject(false);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: RemoveCourseCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Course
             */
            this.GetCourses = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('course/cms_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetCourses');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetCourses');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourses');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCourseById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            course_id: id,
                            scope: 'full',
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('course/cms_info', p);
                    devLog('----------> XHR [SUCCESS]: GetCourseById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateCourse = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            course_id: id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('course/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateCourse');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourse');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '400M':
                            msg = res.data.threat ? res.data.threat.join("\n") : "";
                            break;
                        case '409':
                            msg = "折扣價錢不能大於原價";
                            break;
                        case '404':
                            msg = "找不到課程";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateCourseApproval = async function (course_id, status) {
                try {
                    let payload = {
                        course_id,
                        status,
                    }

                    if (status === 'rejected' || status === 'active') {
                        payload['approve'] = true;
                    } else {
                        payload['approve'] = false;
                    }

                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('course/approval', p);
                    devLog('----------> XHR [SUCCESS]: UpdateCourseApproval');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourseApproval');
                    devLog(res);
                    let msg = "";
                    switch (res.data.code) {
                        case '404':
                            msg = "找不到課程";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * ----------- Class
             */
            this.GetClassesOfCourse = async function (courseId) {
                try {
                    const p = {
                        data: JSON.stringify({
                            course_id: courseId
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('class/course', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetClassesOfCourse');
                        devLog(data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetClassesOfCourse');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClassesOfCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetClasses = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('class/all', p);
                    if (DataValid(data.count) && DataValid(data.class, true)) {
                        devLog('----------> XHR [SUCCESS]: GetClasses');
                        return Promise.resolve({
                            total: data.count,
                            data: data.class
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetClasses');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClasses');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetClassById = async function (courseId, classId) {
                try {
                    const p = {
                        data: JSON.stringify({
                            course_id: courseId,
                            class_id: classId
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('class/class', p);
                    devLog('----------> XHR [SUCCESS]: GetClassById');
                    if (Array.isArray(data) && data.length > 0) {
                        return Promise.resolve(data[0]);
                    } else if (typeof data === "object" && DataValid(data.id)) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(displayError(null));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClassById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetStudentsByClassId = async function (classId) {
                try {
                    const p = {
                        data: JSON.stringify({
                            class_id: classId
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('class/student', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetStudentsByClassId');
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentsByClassId');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentsByClassId');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateClass = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('class/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateClass');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateClass');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetExtraItemsByIdList = async function (id_list) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id_list: id_list
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('item/id_list', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetExtraItemsByIdList');
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetExtraItemsByIdList');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetExtraItemsByIdList');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.AddExtraItem = async function (payload) {
                let uploaded = {
                    inventory: -1
                };
        
                const keys = Object.keys(payload);
                for (let i = 0; i < keys.length; i++) {
                    uploaded[keys[i]] = payload[keys[i]];
                }

                try {
                    const p = {
                        data: JSON.stringify(uploaded)
                    }
                    const data = await Vue.prototype.$XHR.api('item/add', p);
                    if (DataValid(data.data)) {
                        devLog('----------> XHR [SUCCESS]: AddExtraItem');
                        return Promise.resolve(data.data);
                    } else {
                        devLog('----------> XHR [NULL]: AddExtraItem');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: AddExtraItem');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateExtraItem = async function (payload) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('item/update', p);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: UpdateExtraItem');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateExtraItem');
                        return Promise.reject(false);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateExtraItem');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }



            /**
             * ----------- Lesson
             */
            this.GetLessonsOfClass = async function (classId) {
                try {
                    const p = {
                        data: JSON.stringify({
                            class_id: classId
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('lesson/course', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetLessonOfClass');
                        devLog(data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessonOfClass');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessonOfClass');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateLesson = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('lesson/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateLesson');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Redeem Item
             */
            this.GetAllRedeemCategories = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_category/all', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetAllRedeemCategories');
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAllRedeemCategories');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAllRedeemCategories');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.RemoveRedeemCategory = async function (id, title) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            title,
                            active: false
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_category/update', p);
                    if (data) {
                        devLog('----------> XHR [SUCCESS]: RemoveRedeemCategory');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: RemoveRedeemCategory');
                        return Promise.reject(false);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: RemoveRedeemCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateRedeemCategory = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_category/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateRedeemCategory');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateRedeemCategory');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "禮品分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateRedeemCategory = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_category/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateRedeemCategory');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateRedeemCategory');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "禮品分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.GetRedeemCategoryById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_category/detail', p);
                    devLog('----------> XHR [SUCCESS]: GetRedeemCategoryById');

                    if (Array.isArray(data) && data.length > 0) {
                        return Promise.resolve(data[0]);
                    } else if (typeof data === "object" && DataValid(data.id)) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(displayError(null));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetRedeemItems = async function (payload = {}) {
                let upload = {
                    scope: 'basic'
                }

                for (const key in payload) {
                    upload[key] = payload[key];
                }
                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_item/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetRedeemItems');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetRedeemItems');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemItems');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateRedeemItem = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_item/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateRedeemItem');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateRedeemItem');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "禮品分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateRedeemItem = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_item/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateRedeemItem');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateRedeemItem');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetRedeemItemById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            scope: "basic"
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem_item/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetRedeemItemById');
                    if (Array.isArray(data) && data.length > 0) {
                        return Promise.resolve(data[0]);
                    } else if (typeof data === "object" && DataValid(data.id)) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(displayError(null));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemItemById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }


            /**
             * ----------- Redeem Order 禮品換領
             */
            this.GetRedeemOrders = async function (payload = {}) {
                let upload = {};
                for (let key in payload) {
                    upload[key] = payload[key];
                }
                if (upload["end_date"]) {
                    const endDate = new Date(`${upload["end_date"]}T00:00:00+08:00`);
                    endDate.setDate(endDate.getDate() + 1);
                    upload["end_date"] = formatDate(endDate);
                }
                try {
                    const p = {
                        data: JSON.stringify({
                            scope: "basic",
                            ...upload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetRedeemOrders');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetRedeemOrders');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemOrders');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetRedeemOrderById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            scope: "basic"
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetRedeemOrderById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemOrderById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateRedeemOrder = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('redeem/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateRedeemOrder');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateRedeemOrder');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Coupon
             */
            this.GetCoupons = async function (payload = {}) {
                let upload = {
                    scope: 'detail'
                }

                for (const key in payload) {
                    upload[key] = payload[key];
                }
                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('coupon/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetCoupons');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetCoupons');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCoupons');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCouponById = async function (id, scope = 'basic') {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            scope
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('coupon/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetCouponById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCouponById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateCoupon = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('coupon/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateCoupon');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateCoupon');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '602':
                            msg = "優惠碼重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateCoupon = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('coupon/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateCoupon');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCoupon');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Rebate 現金回贈
             */
            this.GetRebates = async function (payload = {}) {
                let upload = {};
                for (let key in payload) {
                    upload[key] = payload[key];
                }
                if (upload["end_date"]) {
                    const endDate = new Date(`${upload["end_date"]}T00:00:00+08:00`);
                    endDate.setDate(endDate.getDate() + 1);
                    upload["end_date"] = formatDate(endDate);
                }
                try {
                    const p = {
                        data: JSON.stringify({
                            scope: "basic",
                            ...upload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('credit_draw/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetRedeemOrders');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetRedeemOrders');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRedeemOrders');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetRebateById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            scope: "basic"
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('credit_draw/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetRebateById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetRebateById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateRebate = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('credit_draw/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateRebate');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateRebate');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.ConfirmCreditGain = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            status: 'active'
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('credit/credit_update', p);
                    devLog('----------> XHR [SUCCESS]: ConfirmCreditGain');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: ConfirmCreditGain');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Workshop Income
             */
            this.GetIncomeSummaries = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            scope: "basic",
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('order/get_report', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetIncomeSummaries');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetIncomeSummaries');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetIncomeSummaries');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetOrdersByWorkshopId = async function (tutor_id, payload = {}) {
                let upload = {
                    tutor_id,
                    scope: "basic",
                };
                for (let key in payload) {
                    upload[key] = payload[key];
                }
                if (upload["end_date"]) {
                    const endDate = new Date(`${upload["end_date"]}T00:00:00+08:00`);
                    endDate.setDate(endDate.getDate() + 1);
                    upload["end_date"] = formatDate(endDate);
                }

                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('order/get_report_detail', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetOrdersByWorkshopId');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetOrdersByWorkshopId');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrdersByWorkshopId');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- 出帳
             */
            // -- 未出帳的list
            this.GetWorkshopUnpaids = async function (workshop_id, payload = {}) {
                let upload = {};
                for (let key in payload) {
                    upload[key] = payload[key];
                }
                if (upload["end_date"]) {
                    const endDate = new Date(`${upload["end_date"]}T00:00:00+08:00`);
                    endDate.setDate(endDate.getDate() + 1);
                    upload["end_date"] = formatDate(endDate);
                }

                try {
                    const p = {
                        data: JSON.stringify({
                            scope: "basic",
                            paid: "unpaid",
                            filter: workshop_id.toString(),
                            ...upload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('salary/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetWorkshopUnpaids');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetWorkshopUnpaids');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetWorkshopUnpaids');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }
            // -- 已出帳的list
            this.GetWorkshopWithdraws = async function (payload = {}) {
                let upload = {};
                for (let key in payload) {
                    upload[key] = payload[key];
                }
                if (upload["end_date"]) {
                    const endDate = new Date(`${upload["end_date"]}T00:00:00+08:00`);
                    endDate.setDate(endDate.getDate() + 1);
                    upload["end_date"] = formatDate(endDate);
                }
                try {
                    const p = {
                        data: JSON.stringify({
                            scope: "basic",
                            ...upload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('salary_withdraw/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetWorkshopWithdraws');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetWorkshopWithdraws');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetWorkshopWithdraws');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetWorkshopWithdrawById = async function (id) {
                let upload = {
                    id,
                    scope: "basic",
                };

                try {
                    const p = {
                        data: JSON.stringify(upload)
                    }
                    const data = await Vue.prototype.$XHR.api('salary_withdraw/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetWorkshopWithdrawById');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetWorkshopWithdrawById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateWorkshopWithdraw = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('salary_withdraw/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateWorkshopWithdraw');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateWorkshopWithdraw');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateWorkshopWithdraw = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('salary_withdraw/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateWorkshopWithdraw');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateWorkshopWithdraw');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ----------- Blog
             */
            this.GetAllBlogCategories = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('blog_category/all', p);
                    if (DataValid(data, true)) {
                        devLog('----------> XHR [SUCCESS]: GetBlogCategories');
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetBlogCategories');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetBlogCategories');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetBlogCategoryById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('blog_category/id', p);
                    devLog('----------> XHR [SUCCESS]: GetBlogCategoryById');

                    if (Array.isArray(data) && data.length > 0) {
                        return Promise.resolve(data[0]);
                    } else if (typeof data === "object" && DataValid(data.id)) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(displayError(null));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetBlogCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateBlogCategory = async function (title) {
                try {
                    const p = {
                        data: JSON.stringify({
                            title,
                            description: title
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('blog_category/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateBlogCategory');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateBlogCategory');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateBlogCategory = async function (id, title) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            title,
                            description: title,
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('blog_category/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateBlogCategory');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateBlogCategory');
                    devLog(res);

                    let msg = "";
                    switch (res.data.code) {
                        case '409':
                            msg = "分類重覆";
                            break;
                    }

                    if (msg) {
                        return Promise.reject({
                            code: res.data.code,
                            message: msg
                        });
                    } else {
                        return Promise.reject(displayError(res));
                    }
                }
            }

            this.RemoveBlogCategory = async function (id, title) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            title,
                            description: title,
                            active: false
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('blog_category/update', p);
                    if (data) {
                        devLog('----------> XHR [SUCCESS]: RemoveBlogCategory');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: RemoveBlogCategory');
                        return Promise.reject(false);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: RemoveBlogCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }


            this.GetBlogs = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            scope: 'basic',
                            ...payload
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('blog/get_all', p);
                    if (DataValid(data.count) && DataValid(data.course, true)) {
                        devLog('----------> XHR [SUCCESS]: GetBlogs');
                        return Promise.resolve({
                            total: data.count,
                            data: data.course
                        });
                    } else {
                        devLog('----------> XHR [NULL]: GetBlogs');
                        return Promise.reject({
                            code: '',
                            message: '無法與伺服器連線，請檢查網路連接'
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetBlogs');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetBlogById = async function (id) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                        })
                    }
                    const data = await Vue.prototype.$XHR.api('blog/get_detail', p);
                    devLog('----------> XHR [SUCCESS]: GetBlogById');
                    if (data) {
                        return Promise.resolve(data);
                    } else {
                        return Promise.reject(displayError(null));
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetBlogById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.CreateBlog = async function (payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify(payload)
                    }
                    const data = await Vue.prototype.$XHR.api('blog/add', p);
                    devLog('----------> XHR [SUCCESS]: CreateBlog');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: CreateBlog');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateBlog = async function (id, payload = {}) {
                try {
                    const p = {
                        data: JSON.stringify({
                            id,
                            ...payload
                        })
                    }

                    const data = await Vue.prototype.$XHR.api('blog/update', p);
                    devLog('----------> XHR [SUCCESS]: UpdateBlog');
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateBlog');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }



        });
    },
}