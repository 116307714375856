import Vue from 'vue'
import VueRouter from 'vue-router'
import mainRoute from './main'
import store from '@/store'

Vue.use(VueRouter)
const vm = new Vue();

const routes = [
  ...mainRoute,
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setInitLoading', false);
  store.dispatch('setLoading', false);
  store.dispatch('setUploadLoading', false);
  window.scrollTo(0, 0);

  let authCheck = true;
  if (to.name !== from.name) {
    authCheck = await vm.$fetcher.AuthCheck();
  }

  if (authCheck === true) {
    if (!to.name || to.name === 'login') {
      next('/member');
    } else {
      next();
    }
  } else {
    if (!to.name || (to.name && to.name !== 'login')) {
      next('/login');
    } else {
      next();
    }
  }
})

const DEFAULT_TITLE = '技能樹 CMS'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (to.meta && to.meta.title ? to.meta.title + ' | ' : '') + DEFAULT_TITLE
  })
})

export default router
