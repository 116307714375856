


export default {
    install(Vue, option) {
        Vue.prototype.$validate = new (function () {
            const DataValid = function (data, checkLength = false) {
                if (data !== undefined && data !== null && data !== '') {
                    if (checkLength === true) {
                        return Array.isArray(data) && data.length > 0;
                    } else {
                        if (typeof data === 'object') {
                            return Object.keys(data).length > 0
                        } else if (Array.isArray(data)) {
                            return data.length > 0
                        }

                        if (data.length > 0) {
                            return data.trim().length > 0
                        }
                        return true;
                    }
                } else {
                    return false
                }
            }

            const regex = {
                email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                hkMobile: /^[3-9][0-9]{7}$/,
                hkPhone: /^(^[2|3|4|5|6|7|8|9])+([0-9]{7})$/,
                twPhone: /^(^[1-9])+([0-9]{8})$/,
                macauPhone: /^([0-9]{8})$/,
                chinaPhone: /^(^[1])+([0-9]{10})$/,

                number: /^[0-9]+$/i,
                floating: /^[0-9]+([.]?[0-9]{1})?$/i,
                floatingWithoutLimit: /^[0-9]+([.]?[0-9]+)?$/i,
                negativeNumber: /^[-]?[0-9]+$/i,
                negativeFloating: /^[-]?[0-9]+([.]?[0-9]+)?$/i,

                blogSlug: /^[\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9-_]+$/i,
                hkid4Letter: /^[A-Z]{1,2}[0-9]{4}$/,
                date: /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
                regexIdNum: /^[a-zA-Z0-9]{8,9}$/i,

                chinName: /^[\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\u3400-\u4DBF\u4E00-\u9FFF]{2,40}$/,
                engName: /^[a-zA-Z\s.]{2,40}$/,
                password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                url: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)$/,
                urlFull: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/, // Full URL
                urlFacebook: /^(https?\:\/\/([\w]+\.))?(facebook\.com)\/.+$/, // HTTP protocol required
                urlIg: /^(https?:\/\/(www\.))?(instagram\.com)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/, // HTTP protocol required
                urlYoutube: /^(https?:\/\/)?(youtube\.com|youtu\.?be)\/.+$/, // HTTP protocol required
            }

            this.DataValid = (data, checkLength = false) => {
                return DataValid(data, checkLength);
            }

            this.required = value => {
                if (DataValid(value) && typeof value === 'string') {
                    return value.trim().length > 0
                }

                return DataValid(value);
            }

            this.isValidValue = (name, value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                let msg = '請輸入有效資料';
                switch (name) {
                    case 'email':
                        msg = '請輸入有效電郵';
                        break;
                    case 'hkPhone':
                    case 'hkMobile':
                    case 'twPhone':
                    case 'macauPhone':
                    case 'chinaPhone':
                        msg = '請輸入有效電話號碼';
                        break;
                    case 'number':
                    case 'floating':
                    case 'floatingWithoutLimit':
                    case 'negativeNumber':
                    case 'negativeFloating':
                        msg = '請輸入有效數字';
                        break;
                    case 'blogSlug':
                        msg = '不可有空格，並只可使用-_等符號';
                        break;
                    case 'chinName':
                        msg = '請輸入有效中文名稱';
                        break;
                    case 'engName':
                        msg = '請輸入有效英文名稱';
                        break;

                    case 'password':
                        msg = '請輸入最少8個英文或數字字元作為密碼';
                        break;
                    case 'urlFull':
                    case 'url':
                        msg = '請輸入有效網址';
                        break;
                    case 'urlFacebook':
                        msg = '請輸入有效Facebook網址';
                        break;
                    case 'urlIg':
                        msg = '請輸入有效Instagram網址';
                        break;
                    case 'urlYoutube':
                        msg = '請輸入有效Youtube網址';
                        break;
                }

                return regex[name].test(value) || msg;
            }

            const checkDoubleFileExtension = (fileName) => {
                const extension = fileName.match(/\.(.+)$/)[1];

                if (extension.split(".").length !== 1) {
                    return true;
                }

                return false;
            }

            const imgSizeCheck = (file) => {
                return new Promise((resolve) => {
                    var dimenMinW = 0,
                        dimenMinH = 0,
                        dimenMaxW = 5472,
                        dimenMaxH = 5472;
                    var reader = new FileReader();
                    reader.onload = function () {
                        var image = new Image();
                        image.src = reader.result;
                        image.onload = function () {
                            var height = this.height;
                            var width = this.width;

                            if (
                                (dimenMinW > 0 && width < dimenMinW) ||
                                (dimenMinH > 0 && height < dimenMinH) ||
                                (dimenMaxW > 0 && width > dimenMaxW) ||
                                (dimenMaxH > 0 && height > dimenMaxH)
                            ) {
                                resolve(false);
                            } else {
                                // file loaded sucessfully
                                resolve(true);
                            }
                            image.onerror = function (error) {
                                // file failed to load
                                resolve(false);
                            };
                        };
                    };
                    reader.onerror = function (error) {
                        // file failed to load
                        resolve(false);
                    };
                    reader.readAsDataURL(file);
                });
            }

            const uploadExtCheck = (name, mimeType, acceptType) => {
                const fileExt = "." + name.split(".").pop().toLowerCase();

                const allowed = acceptType.split(",");
                return allowed.includes(fileExt) && allowed.includes(mimeType);
            }

            this.validateFileInput = (file, acceptType) => {
                return new Promise(async (resolve) => {
                    if (!file) {
                        resolve('請上載檔案');
                    } else {
                        if (checkDoubleFileExtension(file.name)) {
                            resolve('請確保上傳的檔案名稱沒有「.」符號');
                        }

                        if (file.type.indexOf("image") > -1) {
                            const check = await imgSizeCheck(file);
                            if (!check) {
                                resolve('圖片過大 (最大5472x5472)');
                            }

                            if (file.size > 1024 * 1024 * 8) {
                                resolve('檔案過大 (最大8MB)');
                            }
                        } else if (file.type.indexOf("application") > -1) {
                            if (file.size > 1024 * 1024 * 10) {
                                resolve('檔案過大 (最大10MB)');
                            }
                        }

                        if (!uploadExtCheck(file.name.split(".").pop(), file.type, acceptType)) {
                            resolve('不支援檔案格式');
                        }

                        resolve(true);
                    }
                });
            }

            const protocol = (url, forceSSL) => {
                var f = forceSSL !== false;
                if (url != '') {
                    if (url.indexOf('://') < 0) return f ? 'https://' + url : 'http://' + url; else return url;
                }
                return '';
            }

            this.process = {
                url: function (e, forceProtocol, fn, domain) {
                    var cleanDomain = domain || '';
                    const checkVal = e.value.toString();
                    const cleaned = checkVal.replace(/\s/g, '');

                    var filtered = cleaned.replaceAll("https://", '');
                    filtered = filtered.replaceAll("http://", '');

                    if (cleanDomain != '') {
                        filtered = filtered.replaceAll(cleanDomain, '');
                    }

                    var formatted = filtered;
                    if (formatted != '') {
                        formatted = cleanDomain + filtered;
                        formatted = protocol(formatted, forceProtocol);
                    }
                    e.value = formatted;

                    return regex[fn].test(formatted);
                },
            }

        })()
    }
}