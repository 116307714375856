export default {
    install(Vue, option) {
        Vue.prototype.$formatter = new (function () {
            const vm = this;
            /**
             * 
             * @param {String} filename 
             * @param {Number} level - 0: normal | 1: preview | 2: thumb
             * @param {Boolean} secure 
             */
            this.genMediaUrl = (filename, level = 0, secure = false) => {
                if (!filename) {
                    return '';
                }

                const sizes = ['/', '/preview/', '/thumb/'];
                const serverDomain = window.location.origin.indexOf('uat') > -1 ? 'https://cms.uat.skilltree.hk' : 'https://cms.skilltree.hk';
                return serverDomain + '/media/' + (secure === true ? 'secure' : 'cdn') + sizes[level] + filename;
            }


            this.orderCode = (id = -1, workshop_id = -1, create_date = "") => {
                // SK[YY][STORE ID][MM][001]
                if (id === null || id < 0 || workshop_id === null || workshop_id < 0 || create_date === null || create_date === "") {
                    return "";
                }

                return `SK${create_date.substring(2, 4)}${vm.pad(workshop_id, 3)}${create_date.substring(5, 7)}${vm.pad(id, 3)}`;
            }
            this.getOrderIdFromOrderCode = (code) => {
                const valid = /^[sS][kK][0-9]{10,}$/.test(code.trim());
                if (valid) {
                    return parseInt((code.trim()).substring(9));
                }

                return -1;
            }

            this.courseCode = (id = -1, workshop_id = -1, create_date = "") => {
                // [STORE ID][YY][001]
                if (id === null || id < 0 || workshop_id === null || workshop_id < 0 || create_date === null || create_date === "") {
                    return "";
                }

                return `${vm.pad(workshop_id, 3)}${create_date.substring(2, 4)}${vm.pad(id, 3)}`;
            }
            this.getCourseIdFromCourseCode = (code) => {
                const valid = /^[0-9]{8,}$/.test(code.trim());
                if (valid) {
                    return parseInt((code.trim()).substring(5));
                }

                return -1;
            }

            this.redeemCode = (id = -1, create_date = "") => {
                // RDG-[YY][001]
                if (id === null || id < 0 || create_date === null || create_date === "") {
                    return "";
                }
                return `RDG-${create_date.substring(2, 4)}${vm.pad(id, 3)}`;
            }
            this.getRedeemIdFromRedeemCode = (code) => {
                const valid = /^[rR][dD][gG]-[0-9]{5,}$/.test(code.trim());
                if (valid) {
                    return parseInt((code.trim()).substring(6));
                }

                return -1;
            }

            this.rebateCode = (id = -1, create_date = "") => {
                // RDC-[YY][001]
                if (id === null || id < 0 || create_date === null || create_date === "") {
                    return "";
                }

                return `RDC-${create_date.substring(2, 4)}${vm.pad(id, 3)}`;
            }
            this.getRebateIdFromRebateCode = (code) => {
                const valid = /^[rR][dD][cC]-[0-9]{5,}$/.test(code.trim());
                if (valid) {
                    return parseInt((code.trim()).substring(6));
                }

                return -1;
            }

            this.withdrawCode = (id = -1, workshop_id = -1) => {
                // TSF-[StoreID][001] ← e.g. TSF001001
                if (id === null || id < 0 || workshop_id === null || workshop_id < 0) {
                    return "";
                }

                return `TSF${vm.pad(workshop_id, 3)}${vm.pad(id, 3)}`;
            }
            this.getWithdrawIdFromWithdrawCode = (code) => {
                const valid = /^[tT][sS][fF][0-9]{6,}$/.test(code.trim());
                if (valid) {
                    return parseInt((code.trim()).substring(6));
                }

                return -1;
            }


            this.getOrderPaymentSummary = (order) => {
                let extraItemPrice = 0;
                if (order.items && order.items_data) {
                    for (let i = 0; i < order.items.length; i++) {
                        const temp = order.items_data.find(function (e) {
                            return e.id === order.items[i].id;
                        })

                        if (temp) {
                            extraItemPrice += temp.price * order.items[i].count;
                        }
                    }
                }
                const subtotal = order.subtotal; // in workshop side, 未計extra items, 扣咗即時回贈同discount
                const count = order.purchase_count || 1;
                const paymentTotal = subtotal + extraItemPrice;

                let platformFee = 0;
                let immediateRebate = 0; // 即時回贈
                let platformFeeOnlyCourse = 0;

                if (!order.referral_code && count > Vue.prototype.$minReferralNum) { // 有即時回贈
                    if (order.coupon_data) {
                        if (order.coupon_data.coupon_type === 'percentage') {
                            immediateRebate = (order.course_data.discount_price * count * order.coupon_data.amount * 0.01) / count;
                        } else if (order.coupon_data.coupon_type === 'fix_amount') {
                            immediateRebate = (order.course_data.discount_price * count - order.coupon_data.amount) / count;
                        }
                    } else {
                        immediateRebate = order.course_data.discount_price;
                    }
                }

                if (order.course_data.platform_charge) {
                    platformFee = parseFloat((paymentTotal * (order.course_data.platform_charge * 0.01)).toFixed(1));
                    platformFeeOnlyCourse = parseFloat((subtotal * (order.course_data.platform_charge * 0.01)).toFixed(1));
                }

                return {
                    payment_price: paymentTotal, // 序 workshop side，收咗幾錢
                    platform_fee: platformFee,
                    income: paymentTotal - platformFee,
                    immediate_rebate: immediateRebate,
                    platform_fee_only_course: platformFeeOnlyCourse,
                    income_only_course: subtotal - platformFeeOnlyCourse,
                }
            }

            function deletePrefix(e, caretPos, caretEnd, prefix) {
                var str = e.value;
                var caretSet = caretPos;

                if (str == prefix) {
                    e.value = '';
                    caretSet = 0;
                } else {
                    if (caretPos == caretEnd) {
                        if (str.length >= prefix.length) {
                            if (caretPos >= prefix.length) {
                                e.value = str.slice(0, caretPos) + str.slice(caretPos + 1);
                            }
                        } else {
                            e.value = str.slice(0, caretPos) + str.slice(caretPos + 1);
                        }
                    } else {
                        if (caretPos > prefix.length) {
                            e.value = str.slice(0, caretPos) + str.slice(caretEnd);
                        } else {
                            e.value = '';
                            caretSet = 0;
                        }
                    }
                }

                if ('createEvent' in document) {
                    var event = document.createEvent('Event');
                    event.initEvent('input', true, true);
                    e.dispatchEvent(event);
                } else {
                    e.fireEvent('oninput');
                }

                e.focus({ preventScroll: true });
                e.setSelectionRange(caretSet, caretSet);
            }

            function backSpacePrefix(e, caretPos, caretEnd, prefix) {
                var str = e.value;
                var caretSet = caretPos;

                if (str == prefix) {
                    e.value = '';
                    caretSet = 0;
                } else {
                    if (caretPos == caretEnd) {
                        if (caretPos > 0) {
                            if (str.length >= prefix.length) {
                                if (caretPos > prefix.length) {
                                    e.value = str.slice(0, caretPos - 1) + str.slice(caretPos);
                                    caretSet = caretPos - 1;
                                }
                            } else {
                                e.value = str.slice(0, caretPos - 1) + str.slice(caretPos);
                                caretSet = caretPos - 1;
                            }
                        }
                    } else {
                        if (caretPos > prefix.length) {
                            e.value = str.slice(0, caretPos) + str.slice(caretEnd);
                        } else {
                            e.value = '';
                            caretSet = 0;
                        }
                    }
                }

                if ('createEvent' in document) {
                    var event = document.createEvent('Event');
                    event.initEvent('input', true, true);
                    e.dispatchEvent(event);
                } else {
                    e.fireEvent('oninput');
                }

                e.focus({ preventScroll: true });
                e.setSelectionRange(caretSet, caretSet);
            }

            this.inputPrefix = function (ev, prefix) {
                const item = ev.target;
                if (ev.keyCode === 46) {
                    ev.preventDefault();
                    deletePrefix(item, ev.target.selectionStart, ev.target.selectionEnd, prefix);
                } else if (ev.keyCode === 8) {
                    ev.preventDefault();
                    backSpacePrefix(item, ev.target.selectionStart, ev.target.selectionEnd, prefix);
                }
            }

            this.formatMoney = function (price, decPlace = 1, showZeroDecPlace = false) {
                const money = parseFloat(price);
                let d = 0;

                if (money % decPlace !== 0) {
                    // contain decimal places e.g. 1.12
                    d = decPlace
                }
                let decSep = ".";
                let thouSep = ",";
                let sign = money < 0 ? "-" : "";
                var i = String(parseInt(i = Math.abs(Number(money) || 0).toFixed(d)));

                const strLen = i.toString().length;

                let count = 1;
                let returnStr = "";
                for (let k = strLen - 1; k >= 0; k--) {
                    let character = i.charAt(k);
                    returnStr = character + returnStr;
                    if (count === 3) {
                        if (k !== 0) {
                            returnStr = thouSep + returnStr;
                        }
                        count = 1;
                    } else {
                        count++;
                    }
                }

                let decStr = "";
                if (d) {
                    decStr = decSep + Math.abs(money - i).toFixed(d).slice(2);
                } else {
                    if (showZeroDecPlace) {
                        decStr += decSep;
                        for (let k = 0; k < decPlace; k++) {
                            decStr += "0";
                        }
                    }
                }

                return sign + returnStr + decStr;
            }


            // ------ Date related
            /**
             * 
             * @param {String} d - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
             * @returns String - weekday string
             */
            this.displayWeekday = (d) => {
                const w = this.getDay(d);
                return this.formatWeekday(w);
            }

            /**
            * 
            * @param {String} d - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
            * @returns Number - weekday number
            */
            this.getDay = (d) => {
                if (d.length > 10) {
                    return new Date(`${d.split('T')[0]}T12:00:00+08:00`).getDay();
                }

                return new Date(`${d}T12:00:00+08:00`).getDay();
            }

            /**
            * 
            * @param {String} d - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
            * @returns Date
            */
            this.convertStrToDate = (d) => {
                if (d.length > 10) {
                    return new Date(`${d.split('T')[0]}T12:00:00+08:00`);
                }

                return new Date(`${d}T12:00:00+08:00`);
            }

            /**
             * 
             * @param {Number} year 
             * @param {Number} month - start from 0 to 6
             * @param {Number} date 
             * @returns Date
             */
            this.formatDateToDate = (year, month, date) => {
                return this.convertStrToDate(`${year}-${this.pad(month + 1)}-${this.pad(date)}`);
            }

            /**
            * 
            * @param {String} e - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
            * @returns Number - date
            */
            this.dayFormat = (e) => {
                return parseInt(e.substring(8, 10), 10).toString()
            }

            /**
             * 
             * @param {*} d - new Date()
             * @returns String - YYYY-MM-DD
             */
            this.formatDate = (d, iso = false) => {
                let s = `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()}`;
                if (iso === true) {
                    s += `T${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()}:${d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds()}Z`
                }

                return s;
            }

            /**
             * 
             * @param {Number} w - weekday number
             * @returns String - weekday string
             */
            this.formatWeekday = w => {
                const weekday = ['日', '一', '二', '三', '四', '五', '六'];
                if (w > 6 && w < 0) {
                    return weekday[0];
                }

                return weekday[w];
            }

            this.displayCreateDate = (date, showTime = true) => {
                const regFullDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

                if (date && regFullDate.test(date)) {
                    let s = date.substring(0, 10);
                    if (showTime) {
                        s += ' ' + date.split('T')[1].substring(0, 8);
                    }

                    return s;
                } else {
                    return date;
                }
            }

            // ----- Others
            /**
             * 
             * @param {Number | String} n - text/number will be padded
             * @param {Number} l - padded text length
             * @param {String} c - added string 
             * @param {Boolean} r - add text to left-hand-side
             * @param {Boolean} b 
             * @returns 
             */
            this.pad = (n, l, c, r, b) => {
                var s = n.toString(),
                    l = l || 2,
                    r = r !== false,
                    b = b !== false;
                if (s.length > 0) {
                    var p = "";
                    if (!isNaN(n) && s.charAt(0) == "-") {
                        p = "-";
                        s = s.substring(1);
                    }
                    while (s.length < l) {
                        if (r) s = (c || "0") + s;
                        else s += c || "0";
                    }
                    return b ? p + s : s;
                } else return "";
            }

            this.separator = (n, c, l) => {
                var l = l || 3;
                var r = new RegExp("\\B(?=(\\d{" + l + "})+(?!\\d))", "g");
                var p = n.toString().split(".");
                p[0] = p[0].replace(r, c || ",");
                return p.join(".");
            }

            /**
             * 
             * @param {Number} number 
             * @param {Number} decPlace - 取小數點後幾多個位
             * @returns Number
             */
            this.roundUp = (number, decPlace = 1) => {
                const multiplier = Math.pow(10, decPlace || 0);
                return Math.ceil(parseFloat(number) * multiplier) / multiplier;
            }

            this.convertToNum = (n, isInt = false) => {
                if (n !== undefined && n !== null && n !== '' && !isNaN(Number(n))) {
                    if (isInt === true) {
                        return parseInt(n);
                    } else {
                        return parseFloat(n);
                    }
                }

                return 0;

                // return (/^[0-9]+([.]?[0-9]+)?$/i).test(n) || (/^[-]?[0-9]+([.]?[0-9]+)?$/i).test(n) ? parseFloat(n) : 0;
            }

            // ----- Keydown Events
            this.number = event => {
                // [0-9]
                if (event.charCode >= 48 && event.charCode <= 57) {
                    return true
                }

                return event.preventDefault()
            }

            this.floating = event => {
                // [0-9][.][0-9]
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46) {
                    return true
                }

                return event.preventDefault()
            }

            this.numberWithNegative = event => {
                // [-][0-9]
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45) {
                    return true
                }

                return event.preventDefault()
            }

            this.floatingWithNegative = event => {
                // [-][0-9][.][0-9]
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 || event
                    .charCode === 45) {
                    return true
                }

                return event.preventDefault()
            }

            this.alphaNumeric = event => {
                // [0-9A-Za-z]
                if (
                    (event.charCode >= 48 && event.charCode <= 57) ||
                    (event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122)
                ) {
                    return true
                }

                return event.preventDefault()
            }

            this.phone = event => {
                // +[0-9]
                if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43) {
                    return true
                }

                return event.preventDefault()
            }

            this.engName = event => {
                // [a-zA-Z\s.]
                if (
                    (event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122) ||
                    event.charCode === 32 || event.charCode === 46
                ) {
                    return true
                }

                return event.preventDefault()
            }
        })()
    }
}