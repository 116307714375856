export default [
   {
      path: '/',
      component: () => import('@/views/container/PageContainer'),
      children: [
         {
            path: 'login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
               title: '登入',
               layout: 'blank'
            },
         },
         {
            path: 'admin',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'admin',
                  component: () => import('@/views/User/Admin.vue'),
               },
            ]
         },
         {
            path: 'member',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'member',
                  component: () => import('@/views/User/Member.vue'),
                  meta: {
                     title: '用戶管理',
                  }
               },
               {
                  path: 'create',
                  name: 'memberCreate',
                  component: () => import('@/views/User/MemberDetail.vue'),
                  meta: {
                     title: '新增用戶',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'memberDetail',
                  component: () => import('@/views/User/MemberDetail.vue'),
                  meta: {
                     title: '用戶詳情',
                  }
               },
            ]
         },
         {
            path: 'orderCourse',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'orderCourse',
                  component: () => import('@/views/Order/OrderCourse.vue'),
                  meta: {
                     title: '課程訂單',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'orderCourseDetail',
                  component: () => import('@/views/Order/OrderCourseDetail.vue'),
                  meta: {
                     title: '課程訂單詳情',
                  }
               },
            ]
         },
         {
            path: 'orderReferral',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'orderReferral',
                  component: () => import('@/views/Order/OrderReferral.vue'),
                  meta: {
                     title: '推薦積分管理',
                  }
               },
            ]
         },
         {
            path: 'orderGift',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'orderGift',
                  component: () => import('@/views/Order/OrderGift.vue'),
                  meta: {
                     title: '禮品換領訂單',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'orderGiftDetail',
                  component: () => import('@/views/Order/OrderGiftDetail.vue'),
                  meta: {
                     title: '禮品換領訂單詳情',
                  }
               },
            ]
         },
         {
            path: 'rebate',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'rebate',
                  component: () => import('@/views/Order/Rebate.vue'),
                  meta: {
                     title: '現金回贈管理',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'rebateDetail',
                  component: () => import('@/views/Order/RebateDetail.vue'),
                  meta: {
                     title: '現金回贈詳情',
                  }
               },
            ]
         },
         {
            path: 'workshopIncome',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'workshopIncome',
                  component: () => import('@/views/Order/WorkshopIncome.vue'),
                  meta: {
                     title: '工作坊收入明細',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'workshopIncomeDetail',
                  component: () => import('@/views/Order/WorkshopIncomeDetail.vue'),
                  meta: {
                     title: '工作坊收入明細詳情',
                  }
               },
               {
                  path: 'withdraw/:workshopId',
                  name: 'createAccounting',
                  component: () => import('@/views/Order/CreateAccounting.vue'),
                  meta: {
                     title: '建立出帳',
                  }
               },
               {
                  path: 'withdraw-detail/:id',
                  name: 'accountingDetail',
                  component: () => import('@/views/Order/CreateAccounting.vue'),
                  meta: {
                     title: '上載出帳證明',
                  }
               },
            ]
         },
         {
            path: 'courseCategory',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'courseCategory',
                  component: () => import('@/views/Course/CourseCategory.vue'),
                  meta: {
                     title: '課程分類',
                  }
               },
               {
                  path: 'create',
                  name: 'courseCategoryCreate',
                  component: () => import('@/views/Course/CourseCategoryDetail.vue'),
                  meta: {
                     title: '新增課程分類',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'courseCategoryDetail',
                  component: () => import('@/views/Course/CourseCategoryDetail.vue'),
                  meta: {
                     title: '課程分類詳情',
                  }
               },
            ]
         },
         {
            path: 'course',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'course',
                  component: () => import('@/views/Course/Course.vue'),
                  meta: {
                     title: '課程',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'courseDetail',
                  component: () => import('@/views/Course/CourseDetail.vue'),
                  meta: {
                     title: '課程詳情',
                  }
               },
            ]
         },
         {
            path: 'courseClass',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'courseClass',
                  component: () => import('@/views/Course/CourseClass.vue'),
                  meta: {
                     title: '課程班別',
                  }
               },
               {
                  path: 'detail/:courseId/:id',
                  name: 'courseClassDetail',
                  component: () => import('@/views/Course/CourseClassDetail.vue'),
                  meta: {
                     title: '課程班別詳情',
                  }
               },
            ]
         },
         {
            path: 'giftCategory',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'giftCategory',
                  component: () => import('@/views/Redeem/GiftCategory.vue'),
                  meta: {
                     title: '禮品分類',
                  }
               },
               {
                  path: 'create',
                  name: 'giftCategoryCreate',
                  component: () => import('@/views/Redeem/GiftCategoryDetail.vue'),
                  meta: {
                     title: '新增禮品分類',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'giftCategoryDetail',
                  component: () => import('@/views/Redeem/GiftCategoryDetail.vue'),
                  meta: {
                     title: '禮品分類詳情',
                  }
               },
            ]
         },
         {
            path: 'gift',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'gift',
                  component: () => import('@/views/Redeem/Gift.vue'),
                  meta: {
                     title: '禮品',
                  }
               },
               {
                  path: 'create',
                  name: 'giftCreate',
                  component: () => import('@/views/Redeem/GiftDetail.vue'),
                  meta: {
                     title: '新增禮品',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'giftDetail',
                  component: () => import('@/views/Redeem/GiftDetail.vue'),
                  meta: {
                     title: '禮品詳情',
                  }
               },
            ]
         },
         {
            path: 'coupon',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'coupon',
                  component: () => import('@/views/Coupon/Coupon.vue'),
                  meta: {
                     title: '優惠券',
                  }
               },
               {
                  path: 'create',
                  name: 'couponCreate',
                  component: () => import('@/views/Coupon/CouponDetail.vue'),
                  meta: {
                     title: '新增優惠券',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'couponDetail',
                  component: () => import('@/views/Coupon/CouponDetail.vue'),
                  meta: {
                     title: '優惠券詳情',
                  }
               },
            ]
         },
         {
            path: 'notification',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'pushNotification',
                  component: () => import('@/views/PushNotification.vue'),
                  meta: {
                     title: '推送通知',
                  }
               },
            ]
         },
         {
            path: 'blogCategory',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'blogCategory',
                  component: () => import('@/views/Blog/BlogCategory.vue'),
                  meta: {
                     title: '文章分類',
                  }
               },
               {
                  path: 'create',
                  name: 'blogCategoryCreate',
                  component: () => import('@/views/Blog/BlogCategoryDetail.vue'),
                  meta: {
                     title: '新增文章分類',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'blogCategoryDetail',
                  component: () => import('@/views/Blog/BlogCategoryDetail.vue'),
                  meta: {
                     title: '文章分類詳情',
                  }
               },
            ]
         },
         {
            path: 'blog',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'blog',
                  component: () => import('@/views/Blog/Blog.vue'),
                  meta: {
                     title: '文章',
                  }
               },
               {
                  path: 'create',
                  name: 'blogCreate',
                  component: () => import('@/views/Blog/BlogDetail.vue'),
                  meta: {
                     title: '新增文章',
                  }
               },
               {
                  path: 'detail/:id',
                  name: 'blogDetail',
                  component: () => import('@/views/Blog/BlogDetail.vue'),
                  meta: {
                     title: '文章詳情',
                  }
               },
            ]
         },
         {
            path: 'setting',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'setting',
                  component: () => import('@/views/Setting.vue'),
                  meta: {
                     title: '其他設定',
                  }
               },
            ]
         },
         {
            path: 'report',
            component: () => import('@/views/container/Container'),
            children: [
               {
                  path: '',
                  name: 'report',
                  component: () => import('@/views/Report.vue'),
                  meta: {
                     title: '報表',
                  }
               },
            ]
         },
      ],
   },
]
