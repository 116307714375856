import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import api from './api'
import Fetcher from './prototype/Fetcher'
import CommonFn from './prototype/CommonFn'
import Formatter from './prototype/Formatter'
import Validator from './prototype/Validator'

import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCGw1FvrlUmQS_J_80OoXqfTZVT1V-wUF8',
    libraries: 'places',
  }
});

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true,
});

Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)


Vue.prototype.$regionOptions = [
  { text: "中西區", value: "cw" },
  { text: "東區", value: "eastern" },
  { text: "離島區", value: "islands" },
  { text: "九龍城區", value: "kc" },
  { text: "葵青區", value: "kwaitsing" },
  { text: "觀塘區", value: "kwuntong" },
  { text: "北區", value: "north" },
  { text: "西貢區", value: "saikung" },
  { text: "沙田區", value: "shatin" },
  { text: "深水埗區", value: "ssp" },
  { text: "南區", value: "southern" },
  { text: "大埔區", value: "taipo" },
  { text: "荃灣區", value: "tsuenwan" },
  { text: "屯門區", value: "tm" },
  { text: "灣仔區", value: "wanchai" },
  { text: "黃大仙區", value: "wts" },
  { text: "油尖旺區", value: "ytm" },
  { text: "元朗區", value: "yuenlong" },
];

Vue.prototype.$minReferralNum = 4;


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
