<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center px-5">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          height="65px"
          width="220px"
          alt="logo"
          contain
          eager
          class="app-logo mt-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <template v-for="(navItem, index) in navItems">
        <nav-menu-group
          :title="navItem.title"
          :key="`grp-item-${index}`"
          v-if="navItem.children"
          :group="navItem.path"
        >
          <nav-menu-link
            v-for="(item, idx) in navItem.children"
            :key="idx"
            :title="item.title"
            :to="item.to"
          />
        </nav-menu-group>
        <nav-menu-link
          :title="navItem.title"
          :to="navItem.to"
          :key="`item-${index}`"
          v-else
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NavMenuGroup from "./components/NavMenuGroup";
import NavMenuLink from "./components/NavMenuLink";
import nav from "../../_Nav.js";

export default {
  components: {
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.userType,
    }),
    navItems: function() {
      if (this.userType == "admin") {
        return nav.admin;
      } else {
        return nav.staff;
      }
    },
  },
  methods: {
    ...mapActions(["setUser"], "auth"),
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
  .v-list-item {
    padding-left: 22px;
    margin-top: 0.375rem;
    height: 44px;
    min-height: 44px;
  }
}
</style>
