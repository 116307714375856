const SET_DIALOG = 'SET_DIALOG';
const SHOW_DIALOG = 'SHOW_DIALOG';

export default {
   state: {
      title: '',
      message: '',
      type: 'info', // "info" | "success" | "error"
      errorCode: '',
      showDialog: false,
      returnLink: null,
   },
   actions: {
      setDialogMessage: ({ commit }, { title, message, type, returnLink, errorCode }) => {
         let _title = title || '';
         let _message = message || '';
         let _type = type || 'info';
         let _returnLink = returnLink || null;
         let _errorCode = errorCode || '';

         switch (message) {
            case 'noData':
               _title = '找不到資料';
               _message = '找不到資料，請確保網址正確';
               _type = 'error';
               break;
            case 'noPermission':
               _title = '沒有權限';
               _message = '你沒有權限進行此動作';
               _type = 'error';
               break;
         }
         commit(SET_DIALOG, {
            title: _title,
            message: _message,
            type: _type,
            returnLink: _returnLink,
            errorCode: _errorCode,
         });
      },
      setShowDialog: ({ commit }, value) => {
         commit(SHOW_DIALOG, value);
      }
   },
   mutations: {
      [SET_DIALOG]: (state, { title, message, type, returnLink, errorCode }) => {
         state.title = title;
         state.message = message;
         state.type = type;
         state.returnLink = returnLink;
         state.errorCode = errorCode;
      },
      [SHOW_DIALOG]: (state, value) => {
         state.showDialog = value;
      },
   },
}
