export default {
    install(Vue, option) {
        Vue.prototype.$common = new (function () {
            const isDev = (window.location.hostname).includes('localhost') || (window.location.hostname).includes('innpressionhost') || (window.location.hostname).includes('cms.uat');

            this.error = value => {
                if (isDev) {
                    console.error(value)
                }
            }

            this.log = (data, title = '') => {
                if (isDev) {
                    if (title !== '') {
                        console.log(`------------- ${title} -------------`);
                    }
                    if (typeof data !== 'string') {
                        console.log(JSON.parse(JSON.stringify(data)));
                    } else {
                        console.log(data);
                    }
                }
            }

            this.duplicateData = data => {
                return JSON.parse(JSON.stringify(data));
            }

            this.isNewQuery = (newQuery, oldQuery = {}) => {
                for (const key in newQuery) {
                    if (newQuery[key] === '' || newQuery[key] === null) {
                        delete newQuery[key];
                    }
                }
                let isDiff = false;
                if (Object.keys(oldQuery).length !== Object.keys(newQuery).length) {
                    isDiff = true;
                }
                if (!isDiff) {
                    for (const key in newQuery) {
                        if (newQuery[key] != oldQuery[key]) {
                            isDiff = true;
                            break;
                        }
                    }
                }

                return isDiff;
            }

            // ============ File Function =================
            this.getAcceptFileFormat = type => {
                let fileFormatStr = "";
                let fileFormatArr = [];

                if (type === 'image') {
                    fileFormatStr = "image/jpeg,image/jpg,image/png"
                    fileFormatArr = ['png', 'jpeg', 'jpg'];
                } else if (type === 'video') {
                    fileFormatStr = "video/mp4,video/webm"
                    fileFormatArr = ['mp4', 'webm'];
                } else if (type === 'imageWithPDF') {
                    fileFormatStr = "image/jpeg,image/jpg,image/png,application/pdf"
                    fileFormatArr = ['png', 'jpeg', 'jpg', 'pdf'];
                }

                if (Vue.prototype.$validate.DataValid(fileFormatStr) && Vue.prototype.$validate.DataValid(fileFormatArr)) {
                    return {
                        formatStr: fileFormatStr,
                        formatArr: fileFormatArr
                    }
                }

                return null;
            }

            this.formatUploadProgress = (value, total) => {
                return ((value / total) * 100).toFixed(2)
            }


            // ============ Data Process Function =================
        })()
    }
}