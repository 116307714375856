<template>
  <div>
    <v-subheader ref="refVListGroup">{{ title }}</v-subheader>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    group: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.app-navigation-menu {
  .v-list-item {
    padding-left: 22px;
    margin-top: 0.375rem;
    height: 44px;
    min-height: 44px;

    .v-list-item__icon {
      margin-top: 0;
      margin-bottom: 0;
      align-self: center;
    }
  }

  @include theme(v-list-group__header) using ($material) {
    &.v-list-item--active {
      background-color: rgba(
        map-deep-get($material, "text", "primary"),
        map-deep-get($material, "states", "selected")
      );
    }
  }

  .v-subheader {
    align-items: end;
    padding: 0 22px;
  }
}
</style>
