<template>
  <div class="d-flex justify-end">
    <span class=" black--text">{{ userName }}</span>
    <v-menu offset-y nudge-bottom="14" min-width="230" left class="">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="green--text" v-bind="attrs" v-on="on">{{ icons.mdiMenuDown }}</v-icon>
      </template>
      <v-list>
        <!-- Logout -->
        <v-list-item @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">{{ icons.mdiLogoutVariant }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>登出</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mdiLogoutVariant, mdiMenuDown } from "@mdi/js";

export default {
  setup() {
    return {
      icons: { mdiLogoutVariant, mdiMenuDown },
    };
  },
  computed: {
    userName() {
      return this.$store.getters.userName;
    },
  },
  methods: {
    ...mapActions(["setUser"]),
    async logout() {
      await this.$fetcher.Logout();
      this.setUser({ userName: "" });
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
