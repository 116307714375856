export default {
  admin: [
    {
      title: '用戶',
      // path: 'member',
      children: [
        {
          title: '用戶管理',
          to: { name: 'member' },
        },
        // {
        //   title: '所有管理員',
        //   to: { name: 'admin' },
        // },
      ]
    },
    {
      title: '訂單',
      // path: 'order',
      children: [
        {
          title: '課程訂單',
          to: { name: 'orderCourse' },
        },
        {
          title: '推薦積分管理',
          to: { name: 'orderReferral' },
        },
        {
          title: '禮品換領訂單',
          to: { name: 'orderGift' },
        },
        {
          title: '現金回贈管理',
          to: { name: 'rebate' },
        },
        {
          title: '工作坊收入明細',
          to: { name: 'workshopIncome' },
        },
        // {
        //   title: '發票系統',
        //   to: { name: 'orderReceipt' },
        // },

      ]
    },
    {
      title: '課程管理',
      // path: 'course',
      children: [
        {
          title: '課程分類管理',
          to: { name: 'courseCategory' },
        },
        {
          title: '課程管理',
          to: { name: 'course' },
        },
        {
          title: '課程班別',
          to: { name: 'courseClass' },
        },
      ]
    },
    {
      title: '禮品管理',
      // path: 'gift',
      children: [
        {
          title: '禮品分類管理',
          to: { name: 'giftCategory' },
        },
        {
          title: '禮品管理',
          to: { name: 'gift' },
        },
      ]
    },
    {
      title: '宣傳管理',
      // path: 'promotion',
      children: [
        {
          title: '優惠券',
          to: { name: 'coupon' },
        },
        {
          title: '推送通知',
          to: { name: 'pushNotification' },
        },
      ]
    },
    {
      title: '文章管理',
      children: [
        {
          title: '文章分類管理',
          to: { name: 'blogCategory' },
        },
        {
          title: '文章管理',
          to: { name: 'blog' },
        }
      ]
    },
    {
      title: '',
      // path: 'other',
      children: [
        {
          title: '其他設定',
          to: { name: 'setting' },
        },
        {
          title: '報表',
          to: { name: 'report' },
        },
      ]
    }
  ],
}