/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
// API request usign Axios library

import axios from 'axios'

const isDev = window.location.hostname.indexOf('.test') >= 0 || window.location.hostname.indexOf('localhost') >= 0;
const apiEndpoint = (isDev ? '	https://cms.uat.skilltree.hk' : window.location.origin) + '/api/v1/';

export default {
  install(Vue, options) {
    Vue.prototype.$XHR = {
      // POST API request
      post(key, payload, uploadProgress = null) {
        return new Promise((resolve, reject) => {
          const bodyFormData = new FormData()

          for (const e in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, e)) {
              bodyFormData.append(e, payload[e])
            }
          }

          if (document.querySelector('meta[name="xsrf-token"]')) {
            const token = document.querySelector('meta[name="xsrf-token"]').content;
            if (token != '') {
              bodyFormData.append('token', JSON.stringify(token));
            }
          }

          const config = {
            method: 'post',
            url: apiEndpoint + key,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          }

          if (uploadProgress != null) {
            config.onUploadProgress = uploadProgress
          }

          axios(config).then(response => {
            // handle server response
            if (response.data !== undefined && response.data.status !== undefined) {
              if (response.data.status === 'success') {
                resolve(response.data)
              } else {
                console.error('[API] POST failed (non-successful result)')
                reject(response.data)
              }
            } else if (response.data !== undefined && Array.isArray(response.data)) {
              if (response.data[0]) {
                if (response.data[1].status === 'file uploaded and optimized' || response.data[1].status === 'file uploaded') {
                  resolve(response.data[1].filename)
                } else {
                  console.error('[API] POST failed (non-successful result)')
                  reject(response.data)
                }
              } else {
                console.error('[API] POST failed (non-successful result)')
                reject(response.data)
              }
            } else {
              console.error('[API] POST failed (missing data or result)')
              reject(null)
            }

          }).catch(response => {
            // handle error
            console.error('[API] POST failed (connection exception)')
            reject(null)
          })
        })
      },

      // GET request
      get(url) {
        return new Promise((resolve, reject) => {
          const config = {
            method: 'get',
            url: url,
            responseType: 'blob',
            withCredentials: true
          }
          axios(config).then((response) => {
            resolve(response);
          }).catch((error) => {
            console.error(error)
            reject(null)
          });
        });

      },

      async api(key, passObj = {}) {
        const bodyFormData = new FormData();


        if (Object.keys(passObj).length === 0) {
          bodyFormData.append('data', JSON.stringify({}));
        } else {
          for (const e in passObj) {
            if (Object.prototype.hasOwnProperty.call(passObj, e)) {
              bodyFormData.append(e, passObj[e])
            }
          }
        }

        if (document.querySelector('meta[name="xsrf-token"]')) {
          const token = document.querySelector('meta[name="xsrf-token"]').content;
          if (token != '') {
            bodyFormData.append('token', JSON.stringify(token));
          }
        }

        const response = await axios.post((apiEndpoint + key), bodyFormData);
        // const response = await axios((apiEndpoint + key), {
        //   method: "post",
        //   data: bodyFormData,
        //   withCredentials: window.location.origin.indexOf('localhost') > -1 ? false : true,
        // })
        const result = response.data;
        if (result.status === 'success' && result.data !== undefined) {
          return Promise.resolve(result.data);
        } else if (result.status === 'fail') {
          return Promise.reject({
            "result": "fail",
            "data": result.error,
          })
        }

        return Promise.reject({
          "result": "fail",
          "data": null
        })
      },
    }
  },
}
